import * as WebpackBuildEnvironmentModule from '@webpack/Environment/BuildEnvironment'
import * as WebpackEnvModule from '@webpack/Config/Env'

/**
 * This module is the "FRONT-END side" of webpack "process.env" object
 * It should store all the functions related to Env
 * Is redeclared to avoid BE/FE pollution and to avoid ts-compiler problems
 */

/**
 * Declares NodeJS process.env as the Env type defined in webpack Env.ts.
 * This allows to use autocomplete on process.env.field_defined_in_webpack_Env.ts
 */
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NodeJS {
        // eslint-disable-next-line @typescript-eslint/no-namespace,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        export type ProcessEnv = WebpackEnvModule.Env
    }
}

export const IS_DEVELOPMENT_BUILD_ENV = process.env.BUILD_ENV === WebpackBuildEnvironmentModule.BuildEnvironment.development

export const IS_QA_BUILD_ENV = process.env.BUILD_ENV === WebpackBuildEnvironmentModule.BuildEnvironment.qa

export const IS_STAGING_BUILD_ENV = process.env.BUILD_ENV === WebpackBuildEnvironmentModule.BuildEnvironment.staging

export const IS_PRODUCTION_BUILD_ENV = process.env.BUILD_ENV === WebpackBuildEnvironmentModule.BuildEnvironment.production

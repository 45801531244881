import { WebpackMode } from './WebpackMode'
import Console from './../Console'

export enum BuildEnvironment {
    development = 'development',
    production = 'production',
    qa = 'qa',
    staging = 'staging'
}

/**
 * Gets a valid build environment or breaks the whole build.
 * It can be omitted only when webpack is launched in development mode (in that case get will return development)
 *
 * @param webpackModeCLIArgument - one of two ways to launch webpack ("development" or "production")
 * @returns a valid BuildEnvironment (or kills the node process entirely)
 */
export function get(webpackModeCLIArgument: string | undefined):BuildEnvironment {

    if (process.env.BUILD_ENV && process.env.BUILD_ENV in BuildEnvironment) {
        return <BuildEnvironment>process.env.BUILD_ENV
    }

    if (process.env.BUILD_ENV && !(process.env.BUILD_ENV in BuildEnvironment)) {
        Console.err('BUILD_ENV not allowed: %s', process.env.BUILD_ENV,
            'BUILD_ENV must be one of ', BuildEnvironment)

        return process.exit(1)
    }

    Console.warn('BUILD_ENV is not defined:\n',
        'BUILD_ENV environment variable must be one of: ', Object.values(BuildEnvironment),
        '\nYou should define BUILD_ENV in your env variables\n')

    if (webpackModeCLIArgument
        && webpackModeCLIArgument in WebpackMode
        && <WebpackMode>webpackModeCLIArgument == WebpackMode.development) {
        console.log('Build environment will be default configured as "development"\n')

        return BuildEnvironment.development
    }

    Console.err('Implicit BUILD_ENV is not allowed\n',
        'Implicit BUILD_ENV is not allowed in "non-development" webpack mode.\n',
        'You must explicit or BUILD_ENV variable or use --mode=development argument through webpack CLI\n')

    return process.exit(1)

}

/**
 * Utility function to centralize "does it need to be optimized" logic
 *
 * @param buildEnvironment - The environment that will run this build
 * @returns True when this build must be optimized (minified, no debugging ecc... )
 */
export function isBuildOptimized(buildEnvironment: BuildEnvironment) {
    switch (buildEnvironment) {
        case  'development':
            return false
        case 'production':
            return true
        case 'qa':
            return true
        case  'staging':
            return true
    }
}

import { ElmTaggedType, PortFromElm, PortInterface } from 'Lib/MPArchitectureFoundations'
import { devConsoleLog } from 'Lib/Logger'
import hash from 'object-hash'

/**
 * Global typings for window.gtag and window.dataLayer
 */
declare global {
    interface Window {
        _DEFAULT_CONSENT_HAS_BEEN_PUSHED?: boolean,
        dataLayer: IArguments[],
        gtag: Gtag.Gtag
    }
}

/**
 * Holds in Tracker (gtag) and exposes methods to send event through
 */
export class Tracker {
    private gtag: Gtag.Gtag
    private readonly trackingId: string
    private trackedOneShotEvents: Set<string>

    /**
     *  Constructor. Takes the gtag reference and inject
     *
     * @param trackingId - GTM boot id like G-CXXXXXXX
     */
    public constructor(trackingId:string ) {
        this.trackingId = trackingId
        this.loadGtagScript()
        window.dataLayer = window.dataLayer || []
        window.gtag = window.gtag || function ():void {
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments)
        }

        this.gtag = window.gtag
        if (window._DEFAULT_CONSENT_HAS_BEEN_PUSHED === undefined) {
            this.gtag('consent', 'default', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line camelcase
                ad_personalization: 'denied',
                // eslint-disable-next-line camelcase
                ad_storage: 'denied',
                // eslint-disable-next-line camelcase
                ad_user_data: 'denied',
                // eslint-disable-next-line camelcase
                analytics_storage: 'granted',
                // eslint-disable-next-line camelcase
                functionality_storage: 'denied',
                // eslint-disable-next-line camelcase
                personalization_storage: 'denied',
                // eslint-disable-next-line camelcase
                security_storage: 'granted',
                // eslint-disable-next-line camelcase
                wait_for_update: 500,
            })
            this.gtag('set', 'ads_data_redaction', true)
            this.gtag('set', 'url_passthrough', true)
            window._DEFAULT_CONSENT_HAS_BEEN_PUSHED = true
        }
        this.gtag('js', new Date())
        this.gtag('config', this.trackingId)
        this.trackedOneShotEvents = new Set()
    }

    /**
     * Adds a script tag in header and resolves gTag reference
     *
     */
    private loadGtagScript() {
        const script = document.createElement('script')
        script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`)
        script.setAttribute('async', 'true')
        script.onload = () => {
            this.gtag = window.gtag
        }
        document.head.appendChild(script)
    }

    /**
     * Wraps in a safe way GTM event tracking with debugging logs.
     *
     * @param eventName - a custom event name OR one of gtag predefined events labels
     * @param eventParams - Optional event parameters
     */
    private sendEvent(eventName: Gtag.EventNames | string,
        eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams):void {
        this.gtag('event', eventName, eventParams)
        const evt = {
            eventName,
            eventParams,
        }
        devConsoleLog('Tracker:sendEvent', evt)

    }

    /**
     * Tracks Elm's Tracker hit
     *
     * @param hit - a Hit from Elm's Tracker Ports module
     */
    public trackerPort_(hit:Hit):void {
        switch (hit.type_) {
            case 'event':
                this.sendEvent(hit.eventName, hit.eventParams)
                break

            case 'one_shot_event':
                if (!this.eventAlreadyTracked(hit)) {
                    this.sendEvent(hit.eventName, hit.eventParams)
                    this.trackedOneShotEvents.add(hash(hit))
                }
                break
        }
    }

    /**
     * Checks whether the provided hit is already tracked in the current session
     *
     * @param  hit - the hit to check whether it's already been tracked
     * @returns whether the event has already been tracked
     */
    private eventAlreadyTracked(hit: OneShotEvent): boolean {
        return this.trackedOneShotEvents.has(hash(hit))
    }

}

/**
 * TS type version form Elm's Tracker.Hit
 */
export type Hit = Event | OneShotEvent // | Other future variants

export interface Event extends ElmTaggedType {
    eventName: string,
    eventParams?: Gtag.CustomParams,
    type_: 'event' // Discriminator for Elm variant union
}

export interface OneShotEvent extends ElmTaggedType {
    eventName: string,
    eventParams?: Gtag.CustomParams,
    type_: 'one_shot_event'
}

export interface TrackerPortInterface extends PortInterface {
    trackerPort_: PortFromElm <Hit>,
}

export default Tracker

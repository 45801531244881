/**
 * Prints a console warn message with a yellow WARNING title before
 *
 * @param title - the title to print in Yellow
 * @param optionalParams - the following optionalParams to send to console
 */
export function warn(title:string, ...optionalParams:any[]):void {
    console.warn(`\n\x1b[43mWARNING:${title}\x1b[0m`, ...optionalParams)
}

/**
 * Prints a console error message with a red ERROR title before
 *
 * @param title - the title to print in Red
 * @param optionalParams  - the following optionalParams to send to console
 */
export function err(title:string, ...optionalParams:any[]):void {
    console.error(`\n\x1b[41mERROR:${title}\x1b[0m`, ...optionalParams)
}

/**
 * Prints a console log message with a cyan INFO title before
 *
 * @param title - the title to print in Cyan
 * @param optionalParams - the following optionalParams to send to console
 */
export function log(title:string, ...optionalParams:any[]):void {
    console.info(`\n\x1b[42m${title}\x1b[0m`, ...optionalParams)
}

export default  {
    err, log, warn
}

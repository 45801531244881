import '@web-app/theme'
import { IS_PRODUCTION_BUILD_ENV, IS_STAGING_BUILD_ENV } from 'Lib/Env'
import { bootDatadog, bootDatadogRUM, devConsoleError, devConsoleLog } from 'Lib/Logger'
import { onDOMReady } from 'Lib/DOM'
import Tracker from 'Lib/Tracker'

loadCacheServiceWorker()
bootDatadog()
bootDatadogRUM()
const tracker = new Tracker(process.env.GA4_ID)

onDOMReady().then(() => {
    import('@web-app/AppLoader').then(module => new module.AppLoader(tracker) )
})

/**
 * Loads cache service worker (aka Google Workbox).
 * service-worker.js is automatically built by webpack and automatically tracks outputted files with their build hash
 * Check https://webpack.js.org/guides/progressive-web-application/ to learn more
 */
function loadCacheServiceWorker() {
    const isEnabled = (IS_PRODUCTION_BUILD_ENV || IS_STAGING_BUILD_ENV)
    if ('serviceWorker' in navigator  && isEnabled) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js').then((registration) => {
                devConsoleLog('index.ts:loadCacheServiceWorker', registration)
            }).catch((registrationError) => {
                devConsoleError('index.ts:loadCacheServiceWorker', registrationError)
            })
        })
    }

    if (!isEnabled) {
        devConsoleLog('index.ts:loadCacheServiceWorker'
            , 'Service worker is not enabled outside production and staging environment')
    }
}

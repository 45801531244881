import { ElmTaggedType, PortFromElm, PortInterface } from 'Lib/MPArchitectureFoundations'
import { IS_DEVELOPMENT_BUILD_ENV, IS_PRODUCTION_BUILD_ENV, IS_QA_BUILD_ENV, IS_STAGING_BUILD_ENV } from 'Lib/Env'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

/**
 * Boots datadog logger
 */
export function bootDatadog() {
    datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        env: process.env.BUILD_ENV ,
        forwardErrorsToLogs: IS_PRODUCTION_BUILD_ENV,
        service: 'signorina-silvani',
        sessionSampleRate: 100,
        site: 'datadoghq.eu',
        version: process.env.VERSION_ENV
    })
}

/**
 * Boots datadog rum
 */
export function bootDatadogRUM() {
    datadogRum.init({
        applicationId: '1a54bc36-3b1e-42b9-8a78-a1f0835fd62f',
        clientToken: 'pub983e253f100103b838faa6e1f4e71644',
        defaultPrivacyLevel: 'mask',
        env: process.env.BUILD_ENV,
        service: 'signorina-silvani',
        sessionSampleRate: 100,
        site: 'datadoghq.eu',
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        version: process.env.VERSION_ENV,
    })
}

/**
 * Logs through datadog explicit and implicitly throwing runtime error
 */
export function test() {
    datadogLogs.onReady(() => {
        datadogLogs.logger.log('Datadog log test')
        datadogLogs.logger.warn('Datadog warn test')
        datadogLogs.logger.error('Datadog error test')
        setTimeout(() => {
            throw new Error('Simulates error throwing for DATADOG')
        }, 5000)
    })
}

/**
 * `console.log` wrapper, logs only when environment is not Production and not Staging
 *
 * @param codeLocation - where in your code this log has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleLog(codeLocation:string, ...args:any[]) {
    if (IS_DEVELOPMENT_BUILD_ENV || IS_QA_BUILD_ENV) {
        console.log(`Log @${codeLocation}: `, ...args)
    }
}

/**
 * `console.log` wrapper, logs only when environment is not Production and not Staging
 *
 * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleWarn(codeLocation:string, ...args:any[]) {
    if (IS_DEVELOPMENT_BUILD_ENV || IS_QA_BUILD_ENV) {
        console.warn(`Warn @${codeLocation}: `, ...args)
    }
}

/**
 * `console.log` wrapper, logs only when environment is not Production and not Staging
 *
 * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleError(codeLocation:string, ...args:any[]) {
    if (IS_PRODUCTION_BUILD_ENV || IS_STAGING_BUILD_ENV) {
        datadogLogs.onReady(() => {
            datadogLogs.logger.error(`Error @${codeLocation}: `, ...args)
        })
    } else {
        console.error(`Error @${codeLocation}: `, ...args)
    }
}

export type LoggerPortValue = Log | Warn | Error

export interface Log extends ElmTaggedType {
    args: object,
    codeLocation: string,
    type_: 'log'
}

export interface Warn extends ElmTaggedType {
    args: object,
    codeLocation: string,
    type_: 'warn'
}
export interface Error extends ElmTaggedType {
    args: object,
    codeLocation: string,
    type_: 'error'
}

/**
 * Gives custom log capabilities to Elm
 *
 * @param log - serialized log request from elm
 */
export function loggerPort_(log:LoggerPortValue):void {
    switch (log.type_) {
        case 'error':
            devConsoleError(log.codeLocation, log.args)
            break
        case 'warn':
            devConsoleWarn(log.codeLocation, log.args)
            break
        case 'log':
            devConsoleLog(log.codeLocation, log.args)
            break

    }

}

export interface LoggerPortInterface extends PortInterface {
    loggerPort_: PortFromElm <LoggerPortValue>,
}

/**
 * onDOMReady promise
 *
 * @returns Promise resolved once DOM is ready
 */
export async function onDOMReady():Promise<Event | void> {
    return new Promise((resolve) => {
        const isDomReady = document.readyState === 'interactive' || document.readyState === 'complete'
        if ( isDomReady )
            resolve()
        else
            document.addEventListener('DOMContentLoaded', evt => resolve(evt))
    })
}

/**
 * onPageFullyLoaded promise
 *
 * @returns a  promise resolved once  the DOM have been fully parsed AND css/images have been fully loaded
 */
export async function onPageFullyLoaded():Promise<Event | void> {
    return new Promise((resolve) => {
        const isDomLoaded = document.readyState === 'complete'
        if ( isDomLoaded )
            resolve()
        else
            window.addEventListener('load', evt => resolve(evt))

    })
}

let scrollPosition = 0

/**
 * Locks page scroll
 */
export function lockPageScroll() {
    scrollPosition = window.scrollY
    document.documentElement.classList.add('unscrollable')
    document.body.classList.add('unscrollable')
}

/**
 * Unlocks page scroll
 */
export function unlockPageScroll() {
    document.documentElement.classList.remove('unscrollable')
    document.body.classList.remove('unscrollable')
    window.scrollTo(0, scrollPosition)
}

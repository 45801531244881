import { BuildEnvironment } from './BuildEnvironment'
import Console from '../Console'

export enum WebpackMode {
    development = 'development',
    production = 'production'
}

/**
 * Tries to get a valid webpack mode. Implicit mode is allowed only on Dev machine
 *
 * @param buildEnvironment - a valid machine environment
 * @param webpackModeCLIArgument - webpack mode argument (if passed) through CLI
 * @returns a valid mode for this webpack (or kills the node process entirely)
 */
export function get(buildEnvironment: BuildEnvironment, webpackModeCLIArgument: string | undefined): WebpackMode {
    if (webpackModeCLIArgument && webpackModeCLIArgument in WebpackMode) {

        return <WebpackMode>webpackModeCLIArgument
    }

    Console.warn('Webpack mode is not defined')
    if (buildEnvironment == BuildEnvironment.development) {
        console.warn('Webpack mode will be default configured as "development"\n')

        return WebpackMode.development
    }
    Console.err(`Implicit webpack mode is not allowed in ${buildEnvironment}\n`,
        'You must explicit webpack mode through cli when you\'re not in development build environment\n')

    return process.exit(1)

}

/**
 * Utility functions that check if given mode is production
 *
 * @param mode - Webpack mode to check
 * @returns true when mode is production
 */
export function isProduction(mode: WebpackMode): boolean {
    return mode == WebpackMode.production
}
